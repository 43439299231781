import { cloneDeep, map } from "lodash";
import moment from "moment-timezone";

moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);

// action types
export const SET_SCHEDULE = "setVisitSchedule";
export const SET_RECURRING_SCHEDULE = "setVisitRecurringSchedule";
export const SET_TEAM = "setVisitTeam";
export const SET_EDIT_DATA = "setVisitEditData";
export const SET_PREV_SCHEDULE = "setPrevSchedule";
export const SET_NEW_SCHEDULE = "setNewSchedule";
export const CLEAR_VISIT_STATE = "clearVisitState";
export const SET_VISIT_TIMEOUT = "setVisitListingTimeout";

// mutation types
export default {
  state: {
    dbVisitListingTimeout: null,
    dbSchedule: {},
    dbRecurringSchedule: {},
    dbTeam: {},
    dbPrevSchedule: {},
    dbNewSchedule: {},
  },
  getters: {
    getVisitListingTimeout(state) {
      return state.dbVisitListingTimeout;
    },
    getPrevSchedule(state) {
      return state.dbPrevSchedule;
    },
    getNewSchedule(state) {
      return state.dbNewSchedule;
    },
    getSchedule(state) {
      return state.dbSchedule;
    },
    getRecurringSchedule(state) {
      return state.dbRecurringSchedule;
    },
    getTeam(state) {
      return state.dbTeam;
    },
  },
  actions: {
    [SET_SCHEDULE](state, payload) {
      state.commit(SET_SCHEDULE, payload);
    },
    [SET_RECURRING_SCHEDULE](state, payload) {
      state.commit(SET_RECURRING_SCHEDULE, payload);
    },
    [SET_PREV_SCHEDULE](state, payload) {
      state.commit(SET_PREV_SCHEDULE, payload);
    },
    [SET_NEW_SCHEDULE](state, payload) {
      state.commit(SET_NEW_SCHEDULE, payload);
    },
    [SET_TEAM](state, payload) {
      state.commit(SET_TEAM, payload);
    },
    [SET_EDIT_DATA](state, payload) {
      state.commit(SET_EDIT_DATA, payload);
    },
  },
  mutations: {
    [CLEAR_VISIT_STATE](state) {
      state.dbSchedule = {};
      state.dbRecurringSchedule = {};
      state.dbTeam = {};
      state.dbPrevSchedule = {};
      state.dbNewSchedule = {};
    },
    [SET_EDIT_DATA](state, payload) {
      let recurring_schedule = {};

      if (payload?.visit?.schedule?.daily?.id) {
        recurring_schedule = payload?.visit?.schedule?.daily;
      } else if (payload?.visit?.schedule?.monthly?.id) {
        recurring_schedule = payload?.visit?.schedule?.monthly;
      } else if (payload?.visit?.schedule?.weekly?.id) {
        recurring_schedule = payload?.visit?.schedule?.weekly;
      }

      let visit = payload?.visit ?? {};

      let ticket = visit?.ticket ?? {};

      let weekly_arr = [
        "weekly",
        "twice_a_week",
        "fort_nightly",
        "thrice_a_week",
      ];
      let monthly_arr = [
        "monthly",
        "bi_monthly",
        "quarterly",
        "bi_annually",
        "annually",
      ];

      let daily_schedule = {};
      let weekly_schedule = {};
      let monthly_schedule = {};

      if (ticket?.btx_schedule_tab == "daily") {
        daily_schedule = visit?.schedule?.daily;
      } else if (weekly_arr.includes(ticket?.btx_schedule_tab)) {
        weekly_schedule = visit?.schedule?.weekly;
      } else if (monthly_arr.includes(ticket?.btx_schedule_tab)) {
        monthly_schedule = visit?.schedule?.monthly;
      }

      state.dbRecurringSchedule = cloneDeep({
        end_mode: recurring_schedule?.end_mode ?? 2,
        start_date: recurring_schedule?.start_date ?? null,
        end_date: recurring_schedule?.end_date ?? null,
        start_time: recurring_schedule?.start_time ?? null,
        end_time: recurring_schedule?.end_time ?? null,
        occurrence: String(recurring_schedule?.occurrence ?? 1),
        schedule_later: 0,
        pattern: {
          type: ticket?.btx_schedule_tab,
          newPattern: ticket?.btx_schedule_tab,
          newpattern: ticket?.btx_schedule_tab,
          daily: {
            day_count: daily_schedule?.day_count ?? "1",
            recurring_pattern: daily_schedule?.recurring_pattern ?? "2",
          },
          weekly: {
            recurring_pattern: "2",
            week_count: weekly_schedule?.week_count ?? "1",
            weeks: weekly_schedule?.weeks ?? [1],
          },
          monthly: {
            recurring_pattern: monthly_schedule?.recurring_pattern ?? "1",
            day_count: monthly_schedule?.day_count ?? "1",
            week_day: monthly_schedule?.week_day ?? 1,
            week_count: monthly_schedule?.week_count ?? 1,
            day_month_count: monthly_schedule?.day_month_count ?? "1",
            month_count: monthly_schedule?.month_count ?? "1",
          },
        },
      });

      state.dbSchedule = cloneDeep({
        start_date: moment(visit.started_at).format("YYYY-MM-DD"),
        end_date: moment(visit.finished_at).format("YYYY-MM-DD"),
        start_time: moment(visit.started_at).format("HH:mm"),
        end_time: moment(visit.finished_at).format("HH:mm"),
      });

      console.log({
        dbSchedule: state.dbSchedule,
        started_at: visit.started_at,
        finished_at: visit.finished_at,
      });

      state.dbTeam = cloneDeep(payload?.visit?.teams);

      let team = map(state.dbTeam, "id");

      state.dbPrevSchedule = cloneDeep({
        schedule: cloneDeep(state.dbSchedule),
        recurring: cloneDeep(state.dbRecurringSchedule),
        team: {
          assigned_team: cloneDeep(team),
          email_team: +!!(visit?.email ?? true),
        },
      });
    },
    [SET_PREV_SCHEDULE](state, payload) {
      state.dbPrevSchedule = cloneDeep(payload);
    },
    [SET_VISIT_TIMEOUT](state, payload) {
      state.dbVisitListingTimeout = payload;
    },
    [SET_NEW_SCHEDULE](state, payload) {
      state.dbNewSchedule = cloneDeep(payload);
    },
    [SET_SCHEDULE](state, payload) {
      state.dbSchedule = cloneDeep(payload);
    },
    [SET_RECURRING_SCHEDULE](state, payload) {
      state.dbRecurringSchedule = cloneDeep(payload);
    },
    [SET_TEAM](state, payload) {
      state.dbTeam = cloneDeep(payload);
    },
  },
};
